<template>
    <transition appear @enter="onEnter">
        <svg
            id="potato"
            ref="potato"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            :width="size"
            :height="size"
            version="1.1"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 89.61 85.26"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
            class="hero"
        >
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2586435509968">
                    <path
                        ref="heroBody"
                        class="hero_body"
                        fill="#A88657"
                        fill-rule="nonzero"
                        d="M72.48 33.92c-0.97,-4.19 -2,-8.38 -3.27,-12.49 -0.93,-3 -1.99,-5.98 -3.43,-8.78 -1.11,-2.16 -2.46,-4.2 -4.15,-5.96 -1.6,-1.67 -3.49,-3.05 -5.5,-4.18 -1.08,-0.61 -2.19,-1.14 -3.32,-1.64l-0.02 -0.01 -0.03 -0.01c-1.31,-0.41 -2.71,-0.62 -4.08,-0.75 -2.9,-0.27 -5.89,-0.03 -8.62,1 -1.64,0.61 -3.17,1.51 -4.47,2.68 -1.58,1.4 -2.74,3.12 -3.73,4.97 -2.29,4.23 -3.67,8.88 -4.74,13.55 -1.03,4.52 -1.75,9.1 -2.75,13.63 -0.44,2.03 -0.96,4.05 -1.62,6.02 -0.44,1.32 -0.79,2.67 -1.05,4.04 -0.57,2.95 -0.74,6.01 -0.38,9 0.38,3.09 1.34,6.12 3.04,8.75 1.85,2.87 4.52,5.08 7.58,6.58 3.07,1.52 6.48,2.33 9.88,2.65 3.03,0.29 6.1,0.19 9.1,-0.29 3.05,-0.48 6.05,-1.34 8.86,-2.61 2.78,-1.26 5.39,-2.93 7.61,-5.03 2.24,-2.12 4.07,-4.67 5.31,-7.5 1.38,-3.16 1.99,-6.61 1.99,-10.05 0.01,-4.62 -1.18,-9.11 -2.21,-13.57z"
                    />
                    <path
                        class="hero_body shape"
                        fill="#8E6F49"
                        fill-rule="nonzero"
                        d="M29.06 46.85c0,0 -7.17,2.87 -0.91,9.78 6.25,6.9 7.69,-15.12 0.91,-9.78z"
                    />
                    <path
                        class="hero_body shape"
                        fill="#8E6F49"
                        fill-rule="nonzero"
                        d="M56.75 9.38l-0.52 -0.13c-12.64,-1.04 4.82,16.03 7.95,8.21 3.13,-7.82 -7.43,-8.08 -7.43,-8.08z"
                    />
                    <path
                        class="hero_body shape"
                        fill="#8E6F49"
                        fill-rule="nonzero"
                        d="M59.95 49.07c0,0 -4.3,-0.66 -4.04,3.78 0.26,4.43 8.73,-1.57 4.04,-3.78z"
                    />
                    <g>
                        <path
                            class="fil2"
                            fill="#F2D196"
                            fill-rule="nonzero"
                            d="M35.43 29.03c-0.03,0.24 -0.07,0.48 -0.12,0.71 -0.04,0.19 -0.14,0.43 -0.06,0.62 0.04,0.08 0.11,0.1 0.18,0.06 0.19,-0.12 0.22,-0.41 0.25,-0.62 0.03,-0.26 0.02,-0.5 -0.02,-0.76 -0.02,-0.12 -0.22,-0.15 -0.23,-0.01z"
                        />
                        <path
                            class="fil2"
                            fill="#F2D196"
                            fill-rule="nonzero"
                            d="M37.25 27.12c-0.11,0.25 -0.2,0.52 -0.28,0.79 -0.06,0.23 -0.21,0.52 -0.12,0.75 0.04,0.08 0.13,0.12 0.2,0.06 0.2,-0.15 0.22,-0.43 0.27,-0.65 0.08,-0.29 0.14,-0.58 0.19,-0.87 0.02,-0.15 -0.2,-0.23 -0.26,-0.08z"
                        />
                        <path
                            class="fil2"
                            fill="#F2D196"
                            fill-rule="nonzero"
                            d="M37.55 29.87c-0.12,0.24 -0.25,0.47 -0.37,0.7 -0.12,0.21 -0.27,0.39 -0.26,0.64 0,0.05 0.06,0.09 0.11,0.07 0.25,-0.1 0.35,-0.34 0.46,-0.56 0.12,-0.25 0.2,-0.51 0.27,-0.78 0.04,-0.13 -0.16,-0.18 -0.21,-0.07z"
                        />
                        <g ref="eyes">
                            <path
                                ref="eyeRightWhite"
                                class="eye eye_right white"
                                fill="white"
                                fill-rule="nonzero"
                                d="M57.77 31.06c0,2.59 -2.1,4.69 -4.68,4.69 -2.59,0 -4.69,-2.1 -4.69,-4.69 0,-2.59 2.1,-4.69 4.69,-4.69 2.58,0 4.68,2.1 4.68,4.69z"
                            />
                            <path
                                ref="eyeLeftWhite"
                                class="eye eye_left white"
                                fill="white"
                                fill-rule="nonzero"
                                d="M43.85 30.77c0,2.59 -2.1,4.69 -4.68,4.69 -2.59,0 -4.69,-2.1 -4.69,-4.69 0,-2.59 2.1,-4.69 4.69,-4.69 2.58,0 4.68,2.1 4.68,4.69z"
                            />
                            <path
                                ref="eyeRight"
                                class="eye eye_right"
                                fill="#283039"
                                fill-rule="nonzero"
                                d="M54.62 31.64c0,1.49 -1.22,2.71 -2.71,2.71 -1.5,0 -2.71,-1.22 -2.71,-2.71 0,-1.5 1.21,-2.71 2.71,-2.71 1.49,0 2.71,1.21 2.71,2.71z"
                            />
                            <path
                                ref="eyeLeft"
                                class="eye eye_left"
                                fill="#283039"
                                fill-rule="nonzero"
                                d="M42.69 31.82c0,1.5 -1.22,2.72 -2.71,2.72 -1.5,0 -2.71,-1.22 -2.71,-2.72 0,-1.49 1.21,-2.71 2.71,-2.71 1.49,0 2.71,1.22 2.71,2.71z"
                            />
                        </g>
                        <g ref="glasses">
                            <path
                                class="glasses"
                                fill="#C2224C"
                                fill-rule="nonzero"
                                d="M59.97 33.36c-1.45,3.46 -6.17,4.91 -9.47,3.24 -3.06,-1.55 -4.53,-5.72 -2.68,-8.6 0.23,-0.36 1.19,-4.83 7.71,-4.26 4.25,0.37 6.02,5.85 4.44,9.62zm-3.68 -10.3c-7.89,-1.05 -9.51,5.55 -9.48,5.6 -1.45,3.32 0.05,7.42 3.45,8.96 3.79,1.72 9.13,0.13 10.72,-3.91 1.71,-4.33 0.14,-10 -4.69,-10.65z"
                            />
                            <path
                                class="glasses"
                                fill="#C2224C"
                                fill-rule="nonzero"
                                d="M42.9 33.14c-1.45,3.46 -6.17,4.91 -9.47,3.24 -3.06,-1.54 -4.53,-5.71 -2.68,-8.6 0.23,-0.35 1.19,-4.82 7.71,-4.26 4.25,0.37 6.02,5.85 4.44,9.62zm-3.68 -10.29c-7.89,-1.05 -9.51,5.54 -9.48,5.59 -1.45,3.33 0.05,7.43 3.45,8.97 3.79,1.72 9.14,0.12 10.72,-3.92 1.71,-4.33 0.14,-9.99 -4.69,-10.64z"
                            />
                            <path
                                class="glasses"
                                fill="#C2224C"
                                fill-rule="nonzero"
                                d="M67.29 21.2c-1.13,0.7 -2.17,1.68 -3.18,2.54 -1.22,1.04 -2.57,2.09 -3.48,3.42 -0.19,0.28 0.18,0.64 0.46,0.46 1.28,-0.8 2.34,-1.95 3.5,-2.92 0.48,-0.41 4.78,-4.2 4.94,-2.39 0.07,0.74 1.23,0.74 1.16,0 -0.19,-1.93 -2.04,-1.95 -3.4,-1.11z"
                            />
                            <path
                                class="glasses"
                                fill="#C2224C"
                                fill-rule="nonzero"
                                d="M31.74 21.88c-0.35,-0.99 -0.82,-2.1 -1.78,-2.64 -1.69,-0.95 -3.91,-0.26 -3.41,1.97 0.11,0.48 0.93,0.52 1.01,0 0.01,-0.05 0.05,-0.68 0.07,-0.71 0.23,-0.41 0.95,-0.52 1.35,-0.44 0.78,0.15 1.19,0.9 1.48,1.56 0.29,0.62 0.49,1.29 0.64,1.96 0.13,0.59 0.11,1.06 0.49,1.53 0.12,0.15 0.39,0.09 0.47,-0.06 0.5,-0.88 -0.01,-2.28 -0.32,-3.17z"
                            />
                            <path
                                class="glasses"
                                fill="#C2224C"
                                fill-rule="nonzero"
                                d="M46.21 30.52c-0.55,-0.13 -1.6,-0.26 -1.85,0.42 -0.04,0.11 0.01,0.27 0.09,0.35 0.22,0.2 0.46,0.18 0.73,0.18 0.26,0.01 0.49,0.05 0.73,0.14 0.69,0.23 1.01,-0.92 0.3,-1.09z"
                            />
                        </g>
                        <path
                            ref="mouth"
                            class="mouth"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M47.57 46.36c-0.01,0.16 -0.08,0.31 -0.23,0.4 -0.78,0.45 -1.82,0.17 -2.65,-0.32 -0.31,-0.14 -0.58,-0.34 -0.8,-0.59 -0.07,-0.06 -0.14,-0.12 -0.2,-0.19 -0.11,-0.12 -0.16,-0.25 -0.16,-0.37 -0.01,-0.02 -0.02,-0.04 -0.03,-0.06 -0.11,-0.27 0.19,-0.54 0.45,-0.51 0.17,-0.05 0.38,-0.02 0.56,0.12 0.33,0.27 0.66,0.54 1.04,0.73 0.01,0.01 0.03,0.02 0.04,0.02 0.4,0.11 0.83,0.13 1.32,-0.04 0.34,-0.11 0.71,0.19 0.71,0.54 0,0.1 -0.02,0.19 -0.05,0.27z"
                        />
                    </g>
                    <path
                        ref="legRight"
                        class="leg leg_right"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M68.29 76.77c-3.41,0.1 -7.75,0.61 -11.04,2.34 -0.45,0 -0.87,0.02 -1.26,0.07 0.11,-1.87 -0.06,-3.84 -0.05,-5.58 0.01,-1.54 0.51,-4.33 -0.6,-5.57 -0.38,-0.42 -1.22,-0.34 -1.47,0.19 -0.01,0.03 -0.02,0.06 -0.04,0.08 -0.14,0.29 -0.13,0.6 0.01,0.85 0.59,1.92 0.18,4.62 0.2,6.58 0.02,1.42 0.11,6.1 0.4,6.23 0.03,0.1 15.27,1.7 16.72,1.82 2.04,0.18 6.69,0.18 6.75,-2.81 0.07,-4.1 -7.07,-4.28 -9.62,-4.2z"
                    />
                    <path
                        ref="legLeft"
                        class="leg leg_left"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M36.58 68.42c-1.36,3.78 -1.73,7.99 -1.44,12.01 -4.07,-1.49 -8.61,-1.63 -12.87,-0.97 -1.67,0.25 -7.39,0.83 -6.98,3.83 0.31,2.28 3.63,1.99 5.18,1.92 4.96,-0.24 10.08,-0.93 14.85,-2.33 0.22,-0.07 1.43,-0.55 1.47,-1.15 0.32,-4.39 0.07,-8.58 1.66,-12.79 0.43,-1.17 -1.45,-1.69 -1.87,-0.52z"
                    />
                    <g ref="arms">
                        <path
                            ref="armLeft"
                            class="arm arm_left"
                            :class="{ say_hi: animationIs === 'sayHi' }"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M24.39
                        42.3c-5.92,-1.01 -10.76,-3.31 -15.37,-6.38 -1.01,-0.57
                        -1.15,-1.05 -1.15,-1.05 -0.2,-2.1 -0.68,-4.39 -1.98,-5.95
                        -0.26,-0.32 -0.9,-0.07 -0.85,0.35 0.12,1.08 0.51,2.08 0.77,3.13
                        0.12,0.48 0.21,0.97 0.29,1.46 -1.21,-0.9 -2.41,-1.84 -3.62,-2.83
                        -0.57,-0.46 -1.26,0.23 -0.8,0.8 1.12,1.39 2.41,2.65 3.8,3.81
                        -1.61,0.29 -3.21,0.17 -4.99,0.01 -0.5,-0.04 -0.67,0.71
                        -0.25,0.95 2,1.09 4.44,1.01 6.62,0.35 0.14,0.07 0.29,0.1
                        0.44,0.09 4.94,3.5 10.97,5.75 16.63,6.93 1.1,0.23 1.55,-1.48
                        0.46,-1.67z"
                        />
                        <path
                            ref="armRight"
                            class="arm_right"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M78.52 39.9c-3.28,-1.53 -7.76,-1.24 -10.38,1.39 -0.48,0.49 0.21,1.32 0.79,1.05 3.34,-1.6 6.71,-2.77 10.11,-0.45 1.46,1.01 2.67,2.5 3.3,4.17 0.54,1.41 0.24,2.99 0.79,4.34 0.03,0.05 0.07,0.1 0.11,0.15 -1.09,1.48 -1.93,3.93 -1.11,5.58 0.23,0.46 0.86,0.38 0.96,-0.12 0.17,-0.89 0.05,-1.78 0.3,-2.66 0.18,-0.63 0.45,-1.18 0.79,-1.71 0.19,0.47 0.42,0.94 0.58,1.38 0.35,0.95 0.6,2 1.14,2.85 0.21,0.33 0.84,0.18 0.84,-0.22 0.02,-1.01 -0.36,-2.01 -0.67,-2.96 -0.16,-0.5 -0.31,-1.09 -0.52,-1.64 0.39,0.17 0.75,0.4 1.13,0.74 0.77,0.69 1.33,1.55 2.04,2.28 0.39,0.41 1.03,0.07 0.86,-0.49 -0.55,-1.83 -3.23,-4.85 -5.41,-4.12 -0.43,-1.98 -0.17,-3.71 -1.35,-5.67 -1.02,-1.68 -2.52,-3.05 -4.3,-3.89z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </transition>
</template>
<script>
import { gsap } from "gsap";
export default {
    name: "Potato",

    props: {
        size: {
            type: Number,
            required: false,
            default: 90,
        },
        animationIs: {
            type: String,
            required: false,
            default: null,
        },
    },
    methods: {
        onEnter() {
            if (!this.animationIs) return;
            if (this.animationIs === "squeeze") {
                this.squeeze();
                return;
            } else if (this.animationIs === "jump") {
                this.jump();
                return;
            } else if (this.animationIs === "sad") {
                this.sad();
                return;
            }
        },

        squeeze() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 4 });
            gsap.set(this.$refs.heroBody, { transformStyle: "preserve-3d" });
            tl.to(this.$refs.eyes, {
                y: -10,
                x: -10,
                scale: 2,
                duration: 0.5,
                ease: "bounce.out",
                z: 30,
            })
                .to(this.$refs.potato, { rotationY: 35, duration: 0.1 }, 0)
                .to(
                    this.$refs.glasses,
                    {
                        x: -8,
                        y: -8,
                        scale: 1.4,
                        duration: 0.08,
                    },
                    0
                )
                .to(this.$refs.arms, { scale: 0.8, x: 5, duration: 0 }, 0)
                .to(
                    this.$refs.eyes,
                    { y: 0, x: 0, scale: 1, duration: 0.3 },
                    0.7
                )
                .to(
                    this.$refs.glasses,
                    { x: 0, y: 0, scale: 1, duration: 0.1 },
                    0.7
                )
                .to(
                    this.$refs.potato,
                    { rotationY: 0, skewX: 0, scaleX: 1, duration: 0.8 },
                    0.7
                )
                .to(this.$refs.arms, { scale: 1, x: 0, duration: 0.1 }, 0.7);
        },
        jump() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 0.2 });
            gsap.set(this.$refs.legLeft, { transformOrigin: "right top" });
            gsap.set(this.$refs.armLeft, {
                transformOrigin: "right",
                rotate: 0,
            });

            tl.to(
                this.$refs.legRight,
                {
                    rotate: 35,
                    duration: 0.3,
                },
                0
            )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: -25,
                        duration: 0.3,
                    },
                    0
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: -70,
                        x: -7,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 70,
                        x: 8,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.potato,
                    {
                        y: -30,
                        duration: 0.8,
                        rotateZ: 180,
                    },
                    0
                )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.legRight,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.potato,
                    {
                        y: 0,
                        duration: 0.8,
                        ease: "bounce.out",
                    },
                    0.7
                )
                .to(
                    this.$refs.glasses,
                    {
                        y: -10,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.eyes,
                    {
                        y: -5,
                        duration: 0.1,
                    },
                    0.7
                )
                .to(this.$refs.potato, { y: -30, duration: 0.8, rotateZ: 360 })
                .to(this.$refs.potato, { y: 0, duration: 0.3 })
                .to(this.$refs.glasses, { y: 0, duration: 0.1 }, 2.3)
                .to(this.$refs.eyes, { y: 0, duration: 0.1 }, 2.3);
        },
        sad() {
            var tl = gsap.timeline({ repeat: -1 });

            gsap.set("#potato .mouth", {
                rotate: "180deg",
                translateX: 5,
                translateY: 5,
            });

            gsap.set(this.$refs.armLeft, {
                rotate: "300deg",
                translateY: 30,
                translateX: -2,
            });
        },
    },
};
</script>

<style scoped>
* {
    overflow: visible;
}

.say_hi {
    z-index: 0;
    transform-origin: bottom right;
    transform: rotate(30deg) translateX(-15px) translateY(30px);
    animation: hi infinite 5s ease-in-out;
}

@keyframes hi {
    0% {
        transform: rotate(30deg) translateX(-15px) translateY(30px);
    }

    5% {
        transform: rotate(50deg) translateX(-15px) translateY(55px);
    }

    10% {
        transform: rotate(30deg) translateX(-15px) translateY(30px);
    }

    90% {
        transform: rotate(30deg) translateX(-15px) translateY(30px);
    }

    95% {
        transform: rotate(50deg) translateX(-15px) translateY(55px);
    }

    100% {
        transform: rotate(30deg) translateX(-15px) translateY(30px);
    }
}
</style>
